import { createApp } from "vue";
import App from "./App.vue";
import router from "./route";
import axios from "axios";

import {
  Switch,
  Picker,
  Price,
  Radio,
  RadioGroup,
  Cell,
  Video,
  Notify,
  Button,
  InputNumber,
  Icon,
  Input,
  Layout,
  Row,
  Col,
  Navbar,
  Tabs,
  TabPane,
  Avatar,
  Checkbox,
  InfiniteLoading,
  Dialog,
  Swiper,
  Swipe,
  SwiperItem,
  Tabbar,
  TabbarItem,
  Toast,
  Popup,
  Address,
  Elevator
} from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import "./assets/css/style.css";
const app = createApp(App);
app.config.globalProperties.$axios = axios;
app
  .use(router)
  .use(Swipe)
  .use(Address)
  .use(Elevator)
  .use(Cell)
  .use(Radio)
  .use(RadioGroup)
  .use(Price)
  .use(Picker)
  .use(Switch)
  .use(Notify)
  .use(Video)
  .use(Button)
  .use(InputNumber)
  .use(Icon)
  .use(Input)
  .use(Row)
  .use(Layout)
  .use(Col)
  .use(Popup)
  .use(Navbar)
  .use(Tabs)
  .use(TabPane)
  .use(Avatar)
  .use(Toast)
  .use(Checkbox)
  .use(InfiniteLoading)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Dialog)
  .use(Swiper)
  .use(SwiperItem)
  .mount("#app");
// app.use([Button,Icon,Input,Row,Layout,Col,Navbar,Tabs,TabPane,Avatar,Checkbox,InfiniteLoading,Dialog).mount("#app");
