import { createRouter,createWebHistory} from "vue-router";
const routerHistorys = createWebHistory()
 
  const router = createRouter({
    history: routerHistorys,
    routes: [
      {
        path: '/phoneWeb/',
        component: ()=>import ('@/view/login.vue'),
        title:'登录'
      },
      {
        path: '/phoneWeb/forgetPsw',
        name:'forgetPsw',
        component: ()=>import('@/view/forgetPsw.vue'),
        title:'忘记密码'
      },
      {
        path: '/phoneWeb/register',
        name:'register',
        component: ()=>import('@/view/register.vue'),
        title:'注册信息'
      },
      {
        path: '/phoneWeb/phoneLogin',
        name:'phoneLogin',
        component: ()=>import('@/view/phoneLogin.vue'),
        title:'手机快捷登录'
      },
      {
        path: '/phoneWeb/CorporateInfoSet',
        name:'CorporateInfoSet',
        component: ()=>import('@/view/CorporateInfoSet.vue'),
        title:'企业信息登记'
      },
      {
        path: '/phoneWeb/member',
        name:'member',
        component: ()=>import('@/view/member.vue'),
        title:'成员管理'
        
      },
      {
        path: '/phoneWeb/prodetail',
        name:'prodetail',
        component: ()=>import('@/view/prodetail.vue'),
        title:'商品详情'
        
      },
      {
        path: '/phoneWeb/shop',
        name:'shop',
        component: ()=>import('@/view/shop.vue'),
        title:'店铺页面'
      },
      {
        path: '/phoneWeb/about',
        name:'about',
        component: ()=>import('@/view/about.vue'),
        title:'公司简介'
      },
      {
        path: '/phoneWeb/useragreement',
        name:'useragreement',
        component: ()=>import('@/view/UserAgreement.vue'),
        title:'用户协议'
      },
      {
        path: '/phoneWeb/privacypolicy',
        name:'privacypolicy',
        component: ()=>import('@/view/PrivacyPolicy.vue'),
        title:'隐私条款'
      },
      {
        path: '/phoneWeb/invite',
        name:'invite',
        component: ()=>import('@/view/invite.vue'),
        title:'邀请'
      },
      {
        path: '/phoneWeb/bindPhone',
        name:'bindPhone',
        component: ()=>import('@/view/bindPhone.vue'),
        title:'绑定手机号'
      },
      {
        path: '/phoneWeb/order',
        name:'order',
        component: ()=>import('@/view/order.vue'),
        title:'绑定手机号'
      }
    ]
  })
 
export default router 